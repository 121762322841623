import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/logos/logo-icon.png";
import { Link } from "react-router-dom";

const WelcomePage = () => {
    return (
        <CoverLayout
            title=" Comming Soon !"
            description=""
            img={curved9}
        >
            <SoftBox component="form" role="form" >

            </SoftBox>

        </CoverLayout>
    )
}
export default WelcomePage;