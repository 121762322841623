class UserKycModel {
  constructor(
    id,
    createdAt,
    updatedAt,
    bankName,
    accountNo,
    IFSC,
    holder,
    aadharNo,
    aadharFront,
    aadharBack,
    panNo,
    panFile,
    nomineeName,
    sign
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.bankName = bankName;
    this.accountNo = accountNo;
    this.IFSC = IFSC;
    this.holder = holder;
    this.aadharNo = aadharNo ?? "";
    this.aadharFront = aadharFront;
    this.aadharBack = aadharBack ?? "";
    this.panNo = panNo ?? "";
    this.panFile = panFile ?? "";
    this.nomineeName = nomineeName ?? "";
    this.sign = sign ?? "";
  }

  fromJson(jsonData) {
    return new UserKycModel(
      jsonData._id,
      jsonData.createdAt,
      jsonData.updatedAt,
      jsonData.bankName,
      jsonData.accountNo,
      jsonData.IFSC,
      jsonData.holder,
      jsonData.aadharNo ?? "",
      jsonData.aadharFile[0]?.file ?? "",
      jsonData.aadharFile[1]?.file ?? "",
      jsonData.panNo ?? "",
      jsonData.panFile?.file ?? "",
      jsonData.nomineeName,
      jsonData.sign?.file ?? ""
    );
  }
}

export default UserKycModel;
