class UserAddressModel {
  constructor(
    id,
    fullName,
    createdAt,
    updatedAt,
    street1,
    street2,
    city,
    state,
    country,
    postalCode
  ) {
    this.id = id;
    this.fullName = fullName;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.street1 = street1;
    this.street2 = street2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postalCode = postalCode;
  }

  fromJson(jsonData) {
    return new UserAddressModel(
      jsonData._id ?? "",
      jsonData.fullName ?? "user",
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date(),
      jsonData.street1 ?? "",
      jsonData.street2 ?? "",
      jsonData.city ?? "",
      jsonData.state ?? "",
      jsonData.country ?? "",
      jsonData.postalCode ?? ""
    );
  }
}

export default UserAddressModel;
