import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@mui/material";
import { setDialog } from "context";
import { PropTypes } from "prop-types";
import { deleteRoyality } from "Services/endpointes";
import { toast } from "react-toastify";
import ApiClient from "Services/ApiClient";
import { updateRoyality } from "Services/endpointes";
import RoyaltyForm from "../form";

function Designation({ designation, level, rate, status }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {designation}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

Designation.propTypes = {
  designation: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
};

const editRoyality = (id, getRoyality) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateRoyality, formData);
    toast.success(response.message);
    getRoyality();
  } catch (error) {
    console.error("Error adding Royality:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete Royality. Please try again later."
    );
  }
};

const deleteRoyalitys = (id, getAllRoyality) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteRoyality, id);
    toast.success(response?.message);
    getAllRoyality();
  } catch (error) {
    console.error("Error deleting royality:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete royality. Please try again later."
    );
  }
};
const designationsView = {
  columns: [
    { name: "id", align: "left" },
    { name: "designation", align: "left" },
    { name: "level", align: "left" },
    { name: "rate", align: "center" },
    { name: "range", align: "center" },
    { name: "status", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllRoyality) => {
    return data?.map((e) => {
      return {
        id: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.id}
          </SoftTypography>
        ),
        designation: <Designation designation={e.designation} />,
        level: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.level}
          </SoftTypography>
        ),
        rate: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.rate}
          </SoftTypography>
        ),
        range: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.range}
          </SoftTypography>
        ),
        status: (
          <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBadge
              variant="gradient"
              badgeContent={e.status ? "Active" : "Inactive"}
              color={e.status ? "success" : "warning"}
              size="xs"
              container
            />
          </SoftBox>
        ),
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="4px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editRoyality(e.id, getAllRoyality),
                    action: "Update",
                    message: `UPDATE - ${e.type}
                    `,
                    children: <RoyaltyForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: deleteRoyalitys(e.id, getAllRoyality),
                    action: "Delete",
                    message: `DELETE - ${e.type}`,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default designationsView;
