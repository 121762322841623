import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

const AppAccessForm = ({ data }) => {
  return (
    <Card>
      <TextField
        autoFocus
        required
        margin="dense"
        id="index"
        name="index"
        defaultValue={data?.index}
        label="Index"
        type="text"
        fullWidth
        variant="standard"
        sx={{ marginBottom: "20px" }}
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        defaultValue={data.name}
        label="Name"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="key"
        name="key"
        defaultValue={data.key}
        label="key"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="type"
        defaultValue={data.type}
        name="type"
        label="type"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="route"
        defaultValue={data.route}
        name="route"
        label="route"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="auth"
        defaultValue={data.auth}
        name="auth"
        label="auth"
        type="text"
        fullWidth
        variant="standard"
      />
      <FormControl fullWidth>
        <InputLabel
          id="status-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          name="status"
          defaultValue={data.status}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          id="collaps-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          collaps
        </InputLabel>
        <Select
          labelId="collaps-label"
          id="collaps"
          name="noCollapse"
          defaultValue={data.noCollapse}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={true}>Collapse</MenuItem>
          <MenuItem value={false}>No Collapse</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
export default AppAccessForm;

AppAccessForm.defaultProps = {
  data: {},
  type: "new",
};

AppAccessForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
};
