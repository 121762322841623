class PendingVerificationsModel {
  constructor(
    id,
    userId,
    fullName,
    initial,
    email,
    phone,
    bankName,
    kycId,
    accountNo,
    aadhar,
    pan,
    sign,
    IFSC,
    holder,
    aadharNo,
    panNo,
    nomineeName,
    nomineeRel,
    nomineeAge,
    verified,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.userId = userId;
    this.fullName = fullName;
    this.initial = initial;
    this.email = email;
    this.phone = phone;
    this.bankName = bankName;
    this.kycId = kycId;
    this.accountNo = accountNo;
    this.aadhar = aadhar;
    this.pan = pan;
    this.sign = sign;
    this.IFSC = IFSC;
    this.holder = holder;
    this.aadharNo = aadharNo;
    this.panNo = panNo;
    this.nomineeName = nomineeName;
    this.nomineeRel = nomineeRel;
    this.nomineeAge = nomineeAge;
    this.verified = verified;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromArray(jsonData) {
    return jsonData.map((json) => {
      const kycDetails = json.kycDetails[0]; // Assuming there's only one kycDetails object
      return new PendingVerificationsModel(
        json._id ?? null,
        kycDetails.userId ?? "",
        json.fullName ?? "",
        json.intial ?? "",
        json.email ?? "",
        json.phone ?? "",
        kycDetails.bankName ?? "",
        kycDetails._id ?? "",
        kycDetails.accountNo ?? "",
        [kycDetails.aadharFile?.[0]?.file ?? "", kycDetails.aadharFile?.[1]?.file ?? ""],
        kycDetails.panFile?.file,
        kycDetails.sign?.file,
        kycDetails.IFSC ?? "",
        kycDetails.holder ?? "",
        kycDetails.aadharNo ?? "",
        kycDetails.panNo ?? "",
        kycDetails.nomineeName ?? "",
        kycDetails.nomineeRel ?? "",
        kycDetails.nomineeAge ?? "",
        kycDetails.verified ?? false,
        json.createdAt ?? new Date(),
        json.updatedAt ?? new Date()
      );
    });
  }
}

export default PendingVerificationsModel;
