class ProductModel {
    constructor(
        id,
        range,
        space,
        rent,
        basicAmt,
        tax,
        type,
        rule,
        createdAt,
        updatedAt,
    ) {
        this.id = id;
        this.range = range;
        this.space = space;
        this.rent = rent;
        this.basicAmt = basicAmt;
        this.tax = tax;
        this.type = type;
        this.rule = rule;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    fromJson(jsonData) {
        return new ProductModel(
            jsonData._id ?? null,
            jsonData.range,
            jsonData.space,
            jsonData.rent,
            jsonData.basicAmt,
            jsonData.tax,
            jsonData.type,
            jsonData.rule,
            jsonData.createdAt ?? new Date(),
            jsonData.updatedAt ?? new Date(),
        );
    }

    fromArray(jsonDataArray) {
        let data = [];
        for (let json of jsonDataArray) {
            data.push(new ProductModel(
                json._id ?? null,
                json.range,
                json.space,
                json.rent,
                json.basicAmt,
                json.tax,
                json.type,
                json.rule,
                json.createdAt ?? new Date(),
                json.updatedAt ?? new Date(),
            ));
        }
        return data;
    }
}

export default ProductModel;
