/* eslint-disable react/prop-types */
// Knocial Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { useState } from "react";
import { setDialog } from "context";
import { useSoftUIController } from "context";
import ConsultationForm from "../form";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { updateConsultation } from "Services/endpointes";
import { deleteConsultation } from "Services/endpointes";

function Author({ name, id }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Status({ tnxId, status }) {
  if (tnxId === null) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" alignItems="center" flexDirection="column" gap="4px">
          <SoftBadge
            variant="gradient"
            badgeContent="payment pending"
            color="warning"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  } else if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Inactive" color="warning" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="Active" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  }
}

function Verified({ status }) {
  if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge
          variant="gradient"
          badgeContent="Not Verified"
          color="error"
          size="xs"
          container
        />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge
            variant="gradient"
            badgeContent="Verified"
            color="success"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  }
}
const editConsultation = (id, getAllConsultations) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateConsultation, formData);
    toast.success(response.message);
    getAllConsultations();
  } catch (error) {
    console.error("Error editing:", error);
    toast.error(error.response?.data?.message ?? "Failed to editing. Please try again later.");
  }
};

const deletetConsultations = (id, getAllConsultations) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteConsultation, id);
    toast.success(response?.message);
    getAllConsultations();
  } catch (error) {
    console.error("Error deleting deleteLimitReward :", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete source. Please try again later."
    );
  }
};

const ConsultationView = {
  columns: [
    { name: "range", align: "left" },
    { name: "type", align: "left" },
    { name: "rate", align: "center" },
    { name: "status", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllConsultations) => {
    return data.map((e) => {
      const dateObject = new Date(e.createdAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const formattedDate = dateObject.toLocaleDateString("en-GB", options);

      return {
        range: <Author name={e.range} id={e.userId} />,
        type: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.type}
          </SoftTypography>
        ),
        rate: <Author name={e.rate} />,

        status: <Status tnxId={e.transactionId} status={e.status} />,

        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editConsultation(e.id, getAllConsultations),

                    message: `UPDATE - CONSULTATION - ${e.id}`,
                    action: "Update",
                    children: <ConsultationForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: deletetConsultations(e.id, getAllConsultations),

                    message: `DELETE - CONSULTATION - ${e.id}`,
                    action: "Delete",
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default ConsultationView;
