import { Card, TextField } from "@mui/material";
import PropTypes from "prop-types";

const LookUp = ({ data }) => {


  return (
    <Card>
      <TextField
        autoFocus
        required
        disabled
        margin="dense"
        id="name"
        name="name"
        defaultValue={data.userId}
        label="Name"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        margin="dense"
        id="tokenId"
        name="tokenId"
        defaultValue={data.id}
        label="Token"
        type="text"
        fullWidth
        variant="standard"
        InputProps={{
          readOnly: true,
        }}
      />
    </Card>
  );
};

LookUp.defaultProps = {
  data: {},
};

LookUp.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default LookUp;
