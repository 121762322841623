class RoyalityModel {
  constructor(id, range, level, rate, rule, status, designation, createdAt, updatedAt) {
    this.id = id;
    this.range = range;
    this.level = level;
    this.rate = rate;
    this.rule = rule;
    this.status = status;
    this.designation = designation;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new RoyalityModel(
      jsonData._id ?? null,
      jsonData.range,
      jsonData.level,
      jsonData.rate,
      jsonData.rule,
      jsonData.status,
      jsonData.designation,
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(MAP) {
    return MAP.map((json) => {
      return new RoyalityModel(
        json._id ?? null,
        json.range ?? "",
        json.level,
        json.rate,
        json.rule,
        json.status,
        json.designation,
        json.createdAt ?? new Date(),
        json.updatedAt ?? new Date()
      );
    })

  }

}

export default RoyalityModel;
