// @mui material components
import Card from "@mui/material/Card";

// Knocial Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knocial Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import SoftButton from "components/SoftButton";
import { Checkbox, FormControlLabel, Grid, Icon, TablePagination } from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { useEffect } from "react";
import Table from "examples/Tables/Table";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { useSoftUIController, startLoading, setLoading, setUsers } from "context";
import React from "react";
import { getLimitReward } from "Services/endpointes";
import { setLimitRewrds } from "context";
import LimitRewardsView from "./data/limitrewards";
import { createLimitReward } from "Services/endpointes";
import LimitRewardForm from "./form";
import { setDialog } from "context";
import Footer from "examples/Footer";

function Limitrewards() {
  const [controller, dispatch] = useSoftUIController();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { limitrewards } = controller;
  const getAllLimitRewards = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getData(getLimitReward);
      if (response.status == 200) {
        setLimitRewrds(dispatch, response.data);
        toast.success(response?.message);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };
  const addLimitReward = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createLimitReward, formData);
      getAllLimitRewards();
      setDialog(dispatch, [response]);
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading(dispatch, false);
    }
  };
  useEffect(() => {
    limitrewards.length < 1 && getAllLimitRewards();
  }, []);

  let memoizedRows = LimitRewardsView.rows(limitrewards, dispatch, getAllLimitRewards);

  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"limitrewards"} call={getAllLimitRewards} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="12%"
              >
                <SoftTypography variant="h6">All Claim List</SoftTypography>
                <SoftButton
                  variant="outlined"
                  color="success"
                  size="small"
                  iconOnly
                  circular
                  onClick={() => getAllLimitRewards()}
                >
                  <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
                </SoftButton>
              </SoftBox>
              <SoftButton
                variant="gradient"
                color="dark"
                ml={2}
                onClick={() => {
                  setDialog(dispatch, [
                    {
                      call: addLimitReward,
                      status: "form",
                      message: "CREATE NEW Limit Reward",
                      action: "Add New",
                      children: <LimitRewardForm />,
                    },
                  ]);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;
              </SoftButton>
            </SoftBox>

            {limitrewards?.length > 0 ? (
              <Table columns={LimitRewardsView.columns} rows={memoizedRows} />
            ) : (
              <SoftBox mt={4}>
                <SoftBox mb={1.5}>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Grid item container spacing={3}>
                        <Grid item xs={12} xl={12}>
                          <DefaultInfoCard
                            icon="cloud"
                            title={`You Don't have an active Rewards yet. Add Rewards to your portfolio and start earning.`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Limitrewards;
