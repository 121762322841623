class LimitRewards {
  constructor(id, userId, type, rewardId, reward, status, createdAt, updatedAt) {
    this.id = id;
    this.userId = userId;
    this.type = type;
    this.rewardId = rewardId;
    this.reward = reward;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new LimitRewards(
      jsonData._id ?? null,
      jsonData.userId,
      jsonData.type,
      jsonData.rewardId,
      jsonData.reward,
      jsonData.status ?? false,
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    let data = [];
    for (let json of jsonData) {
      data.push(
        new LimitRewards(
          json._id ?? null,
          json.userId,
          json.type,
          json.rewardId,
          json.reward,
          json.status ?? false,
          json.createdAt ?? new Date(),
          json.updatedAt ?? new Date()
        )
      );
    }
    return data;
  }
}

export default LimitRewards;
