class SourceeModel {
    constructor(
        id,
        title,
        type,
        rule,
        range,
        status,
        createdAt,
        updatedAt,
    ) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.rule = rule;
        this.range = range;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    fromJson(jsonData) {
        return new SourceeModel(
            jsonData._id ?? null,
            jsonData.title,
            jsonData.type,
            jsonData.rule,
            jsonData.range,
            jsonData.status ?? false,
            jsonData.createdAt ?? new Date(),
            jsonData.updatedAt ?? new Date(),
        );
    }

    fromArray(jsonData) {
        let data = [];
        for (let json of jsonData) {
            data.push(new SourceeModel(
                json._id ?? null,
                json.title,
                json.type,
                json.rule,
                json.range,
                json.status ?? false,
                json.createdAt ?? new Date(),
                json.updatedAt ?? new Date(),
            ));
        }
        return data;
    }
}

export default SourceeModel;
