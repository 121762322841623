import { useState, useEffect } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Knocial Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knocial Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Knocial Dashboard React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from "context";

// Images
import { Avatar, Box, Divider, Stack, Grid, Typography } from "@mui/material";
import { getUserById } from "Services/endpointes";
import {
  setUser,
  setDialog,
  setConnection,
  setRewrds,
  setRoutes,
  setTransactions,
  setSources,
  setRoyality,
  setRent,
  setProducts,
  setConsultation,
  setIncomes,
  setLimitRewrds,
} from "context";
import { getConnectionByUserID } from "Services/endpointes";
import { getIncomeByUserId } from "Services/endpointes";
import { getLimitRewardById } from "Services/endpointes";
import { getProductById } from "Services/endpointes";
import { getRentByUserId } from "Services/endpointes";
import { getRewardById } from "Services/endpointes";
import { getRoyalityById } from "Services/endpointes";
import { getSourceById } from "Services/endpointes";
import { getTransactionsByUserId } from "Services/endpointes";
import { Diversity3 } from "@mui/icons-material";
import SoftInput from "components/SoftInput";

function DashboardNavbar({ absolute, light, isMini, endpoint, call }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { user, notifications } = controller;

  const logoutHandler = () => {
    setDialog(dispatch, [
      {
        message: "Are you sure you want to Log out?",
        status: "Logout",
      },
    ]);
  };
  const endpoints = {
    users: getUserById,
    appaccess: "",
    connections: getConnectionByUserID,
    consultation: "",
    income: getIncomeByUserId,
    limitrewards: getLimitRewardById,
    products: getProductById,
    rents: getRentByUserId,
    rewards: getRewardById,
    royality: getRoyalityById,
    sources: getSourceById,
    transaction: getTransactionsByUserId,
  };
  const setData = {
    users: setUser,
    appaccess: setRoutes,
    connections: setConnection,
    consultation: setConsultation,
    income: setIncomes,
    limitrewards: setLimitRewrds,
    products: setProducts,
    rents: setRent,
    rewards: setRewrds,
    royality: setRoyality,
    sources: setSources,
    transaction: setTransactions,
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const generateReferLink = (id) => {
    const referLink = "https://account.knocialindia.com";

    return `${referLink}/sign-up/1?sponsorId=${id}&placementId=${id}`;
  };
  function generateWhatsAppMessage(referralLink) {
    const companyName = "Knocial India Limited";
    const message = `👋 Hey there!\n\nLooking to start earning?💰\nClick on this link to get started with ${companyName}.\n\nLink: ${referralLink}\n\nJoin us at ${companyName} and explore exciting opportunities to earn from the comfort of your home.\n\nHappy earning! 🚀\n\nRegards\n KnomasTree Team`;
    return message;
  }

  const handleSend = (message) => {
    // Construct the WhatsApp URL with only the prewritten message
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new window or tab
    window.open(whatsappUrl, "_blank");
  };
  const handleCopyLink = (user) => {
    const referLink = generateReferLink(user.id);

    let message = generateWhatsAppMessage(referLink);
    setDialog(dispatch, [
      {
        status: "form",
        title: "Here is Your Refer Link",
        children: (
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Icon small>link</Icon>
            </Grid>
            <Grid item xs={11}>
              <Typography fontSize={13} whiteSpace={"nowrap"}>
                {" "}
                {referLink}
              </Typography>
            </Grid>
          </Grid>
        ),
        action: "Share",
        call: () => handleSend(message),
      },
    ]);
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <Box>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          component={Link}
          to="/profile"
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="warning"
        >
          <Divider />
          <Icon fontSize="1rem">account_circle</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            My Profile
          </SoftTypography>
        </SoftBox>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          component={Link}
          to="/create-staff"
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="warning"
        >
          <Divider />
          <Diversity3 size="12px" />
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Create Staff
          </SoftTypography>
        </SoftBox>

        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          onClick={() => handleCopyLink(user)}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          color="info"
        >
          <Divider />
          <Icon fontSize="1rem">share_icon</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Refer Link
          </SoftTypography>
        </SoftBox>
        <SoftBox
          variant="gradient"
          px={1}
          my={0.5}
          onClick={() => logoutHandler()}
          display="flex"
          sx={{ cursor: "pointer" }}
          alignItems="center"
          color="error"
        >
          <Divider />
          <Icon fontSize="1rem">logout_icon</Icon>
          <SoftTypography cursor="pointer" fontSize="1rem" pl={1} component="span">
            Log Out
          </SoftTypography>
        </SoftBox>
      </Box>
    </Menu>
  );
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const handleFocus = (event) => {
    event.target.classList.add("focused");
  };

  const handleBlur = (event) => {
    event.target.classList.remove("focused");
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              call={call}
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </SoftBox>
          <SoftBox pr={1} onFocus={handleFocus} onBlur={handleBlur}>
            <SoftInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "left" }}
            />
          </SoftBox>
          <SoftBox color={light ? "white" : "inherit"}>
            <IconButton
              size="small"
              color="inherit"
              sx={navbarIconButton}
              aria-controls="Profile-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Stack direction="row" spacing={2}>
                <Avatar alt="Remy Sharp" width="100%" src="51365.jpg" />
              </Stack>
            </IconButton>
            {renderMenu()}
          </SoftBox>

          <IconButton
            size="small"
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon className={light ? "text-white" : "text-dark"}>
              {!miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  endpoint: "",
  call: () => { },
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  endpoint: PropTypes.string,
  call: PropTypes.func,
};

export default DashboardNavbar;
