import React, { lazy, Suspense } from "react";
import Loading from "layouts/loading";
import RecipeReviewCard from "layouts/verifyAccount";
import Sources from "layouts/sources";
import WelcomePage from "layouts/welcomePage";
import Office from "examples/Icons/Office";
import Royality from "layouts/Royality";
import Limitrewards from "layouts/Limitrewards";
import AppAccess from "layouts/appAccess";
import Transactions from "layouts/Transaction";
import Rewards from "layouts/Rewards";
import Consultation from "layouts/Consultation";
import {
  AddCard,
  AddShoppingCart,
  Apps,
  Bolt,
  Business,
  CurrencyRupee,
  DashboardCustomize,
  Diversity3,
  EmojiEvents,
  Groups2,
  Label,
  Person,
  ShoppingCart,
  StarHalf,
  Timeline,
} from "@mui/icons-material";
import ResetPassword from "layouts/authentication/forget-password";
const Dashboard = lazy(() => import("layouts/dashboard"));
const Users = lazy(() => import("layouts/users"));
const Connections = lazy(() => import("layouts/connections"));
const Billing = lazy(() => import("layouts/billing"));
const Profile = lazy(() => import("layouts/profile"));

const Shop = lazy(() => import("examples/Icons/Shop"));
const CustomerSupport = lazy(() => import("examples/Icons/CustomerSupport"));
const CreditCard = lazy(() => import("examples/Icons/CreditCard"));
const Income = lazy(() => import("layouts/Income/income"));
const Rents = lazy(() => import("layouts/Rents"));
const Products = lazy(() => import("layouts/Products"));
const CreateMembers = lazy(() => import("layouts/CreateMember"));
const SignUp = lazy(() => import("layouts/authentication/sign-up"));
const SignIn = lazy(() => import("layouts/authentication/sign-in"));

const routes = [
  // admin accessable routes
  {
    type: "collapse",
    name: "Home",
    key: "home",
    auth: "admin",
    route: "/home",
    icon: <DashboardCustomize size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    auth: "admin",
    key: "users",
    route: "/users",
    icon: <Groups2 size="12px" />,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transaction",
    key: "transaction",
    auth: "admin",
    route: "/transaction",
    icon: <AddCard size="12px" />,
    component: <Transactions />,
    noCollapse: true,
  },

  {
    type: "route",
    name: "Create Staff",
    key: "create-staff",
    auth: "admin",
    route: "/create-staff",
    icon: <Diversity3 size="12px" />,
    component: <CreateMembers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: " Connections",
    key: "connections",
    route: "/connections",
    icon: <ShoppingCart size="12px" />,
    component: <Connections />,
    auth: "admin",
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Income",
    key: "income",
    auth: "admin",
    route: "/income",
    icon: <CurrencyRupee size="12px" />,
    component: <Income />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Rents",
    key: "rents",
    auth: "admin",
    route: "/rents",
    icon: <Timeline size="12px" />,
    component: <Rents />,
    noCollapse: true,
  },

  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    auth: "admin",
    icon: <Person size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },

  {
    type: "route",
    name: "welcome",
    key: "welcome",
    auth: "any",
    route: "/welcome-page",
    component: <WelcomePage />,
    noCollapse: false,
  },

  {
    type: "route",
    name: "VerifyAccount",
    key: "VerifyAccount",
    auth: null,
    route: "/verify-account/:id",
    icon: <Shop size="12px" />,
    component: <RecipeReviewCard />,
    noCollapse: true,
  },
  {
    type: "route",
    name: "SignUp",
    Key: "signup",
    auth: null,
    route: "/sign-up",
    component: <SignUp />,
    noCollapse: false,
  },
  {
    type: "route",
    name: "SignIn",
    Key: "signin",
    auth: null,
    route: "/",
    component: <SignIn />,
    noCollapse: false,
  },
  {
    type: "route",
    name: "ResetPassword",
    Key: "resetpassword",
    auth: null,
    route: "/reset-password",
    component: <ResetPassword />,
    noCollapse: false,
  },
  { type: "title", title: "Knocial Sources", key: "knocialSources" },
  {
    type: "collapse",
    name: "AppAccess",
    key: "appAccess",
    auth: "admin",
    route: "/app-access",
    icon: <Apps size="12px" />,
    component: <AppAccess />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Consultations",
    key: "consultations",
    auth: "admin",
    route: "/consultations",
    icon: <Shop size="12px" />,
    component: <Consultation />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    auth: "admin",
    route: "/products",
    icon: <AddShoppingCart size="12px" />,
    component: <Products />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Sources",
    key: "sources",
    auth: "admin",
    route: "/sources",
    icon: <Business size="12px" />,
    component: <Sources />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Claims",
    key: "claims",
    route: "/claims",
    icon: <StarHalf size="12px" />,
    component: <Limitrewards />,
    auth: "admin",
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Rewards",
    key: "rewards",
    auth: "admin",
    route: "/rewards",
    icon: <EmojiEvents size="12px" />,
    component: <Rewards />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "royality",
    key: "royality",
    auth: "admin",
    route: "/royality",
    icon: <Bolt size="12px" />,
    component: <Royality />,
    noCollapse: true,
  },
];
export default routes;

export const components = {
  dashboard: (
    <Suspense fallback={<Loading />}>
      <Dashboard />
    </Suspense>
  ),
  sources: (
    <Suspense fallback={<Loading />}>
      <Sources />
    </Suspense>
  ),
  VerifyAccount: (
    <Suspense fallback={<Loading />}>
      <RecipeReviewCard />
    </Suspense>
  ),
  createMembers: (
    <Suspense fallback={<Loading />}>
      <CreateMembers />
    </Suspense>
  ),
  rewards: (
    <Suspense fallback={<Loading />}>
      <Rewards />
    </Suspense>
  ),
  transaction: (
    <Suspense fallback={<Loading />}>
      <Transactions />
    </Suspense>
  ),
  consultations: (
    <Suspense fallback={<Loading />}>
      <Consultation />
    </Suspense>
  ),
  myTeam: (
    <Suspense fallback={<Loading />}>
      <Users />
    </Suspense>
  ),
  connections: (
    <Suspense fallback={<Loading />}>
      <Connections />
    </Suspense>
  ),
  income: (
    <Suspense fallback={<Loading />}>
      <Income />
    </Suspense>
  ),
  rents: (
    <Suspense fallback={<Loading />}>
      <Rents />
    </Suspense>
  ),
  products: (
    <Suspense fallback={<Loading />}>
      <Products />
    </Suspense>
  ),
  account: (
    <Suspense fallback={<Loading />}>
      <Billing />
    </Suspense>
  ),
  profile: (
    <Suspense fallback={<Loading />}>
      <Profile />
    </Suspense>
  ),
  royality: (
    <Suspense fallback={<Loading />}>
      <Royality />
    </Suspense>
  ),
  claims: (
    <Suspense fallback={<Loading />}>
      <Limitrewards />
    </Suspense>
  ),
  appAccess: (
    <Suspense fallback={<Loading />}>
      <AppAccess />
    </Suspense>
  ),
};

export const icons = {
  dashboard: <Shop size="12px" />,
  createMembers: <Office size="12px" />,
  myTeam: <Office size="12px" />,
  connections: <Office size="12px" />,
  income: <Office size="12px" />,
  rents: <Office size="12px" />,
  products: <CreditCard size="12px" />,
  account: <CreditCard size="12px" />,
  appAccess: <CreditCard size="12px" />,
  profile: <CustomerSupport size="12px" />,
};
