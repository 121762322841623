class IncomeLog {
  constructor(id, userId, amount, type, tds, status, createdAt, updatedAt) {
    this.id = id;
    this.userId = userId;
    this.amount = amount;
    this.type = type;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromArray(jsonData) {
    return jsonData.map(
      (JSON) =>
        new IncomeLog(
          JSON._id ?? null,
          JSON.userId,
          JSON.amount,
          JSON.type ?? "",
          JSON.status ?? true,
          JSON.createdAt ?? new Date(),
          JSON.updatedAt ?? new Date()
        )
    );
  }
}

export default IncomeLog;
