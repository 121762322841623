// @mui material components
import Card from "@mui/material/Card";

// Knocial Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knocial Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import SoftButton from "components/SoftButton";
import { Grid, Icon, TablePagination } from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { useEffect } from "react";
import Table from "examples/Tables/Table";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { useSoftUIController, startLoading, setLoading } from "context";
import React from "react";
import TokenView from "./data/token";
import { setDialog } from "context";
import { createBusinessTransaction } from "Services/endpointes";
import BusinessTransForm from "./form";
import Footer from "examples/Footer";
import { getUsersTransactions } from "Services/endpointes";
import { setTransactions } from "context";

function Token() {
  const [controller, dispatch] = useSoftUIController();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { transactions } = controller;
  const getAllBusinessTransaction = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataWithPagination(getUsersTransactions, 0, 200);
      console.log(response.status);
      if (response.status == 200) {
        setTransactions(dispatch, response.data);
      } else setDialog(dispatch, response);
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error?.toString());
    }
  };
  useEffect(() => {
    transactions.length < 1 && getAllBusinessTransaction();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addBusinessTrans = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createBusinessTransaction, formData);
      if (response.status === 200) {
        getAllBusinessTransaction();
        setDialog(dispatch, [response]);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setDialog(dispatch, [error?.response?.data]);
      // toast.error(error?."Failed to add source. Please try again later.");
    }
  };
  let memoizedRows = TokenView.rows(transactions, dispatch, getAllBusinessTransaction);
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    console.log(inputValue);
    if (inputValue.length === 10) {
      callApiWithInputValue(inputValue);
    }
  };
  const callApiWithInputValue = async (inputValue) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataByParam(getUsersTransactions, inputValue);
      if (response?.status === 200) {
        setTransactions(dispatch, response.data);
      }
      toast.success(response?.message);
      setDialog(dispatch, []);
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"token"} call={getAllBusinessTransaction} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
          >
            <SoftButton
              variant="gradient"
              color="dark"
              ml={2}
              onClick={() => {
                setDialog(dispatch, [
                  {
                    call: addBusinessTrans,
                    status: "form",
                    message: "Create New Transactions",
                    action: "Confirm",
                    children: <BusinessTransForm />,
                  },
                ]);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;
            </SoftButton>
          </SoftBox>

          {transactions?.length > 0 ? (
            <>
              <Table columns={TokenView.columns} rows={memoizedRows} />
              <SoftBox mt={2} display="block" width={90}>
                <TablePagination
                  component="span"
                  count={100}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </SoftBox>
            </>
          ) : (
            <SoftBox mt={4}>
              <SoftBox mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item lg={12}>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} xl={12}>
                        <DefaultInfoCard
                          icon="cloud"
                          title={`You Don't have an active connection yet. Add connection to your portfolio and start earning.`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Token;
