class ConsultationModel {
  constructor(id, range, type, rate, status, createdAt, updatedAt) {
    this.id = id;
    this.range = range;
    this.type = type;
    this.rate = rate;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromArray(jsonData) {
    return jsonData.map(
      (JSON) =>
        new ConsultationModel(
          JSON._id ?? null,
          JSON.range ?? 0,
          JSON.type,
          JSON.rate ?? 0.0,
          JSON.status ?? true,
          JSON.createdAt ?? new Date(),
          JSON.updatedAt ?? new Date()
        )
    );
  }
}

export default ConsultationModel;
