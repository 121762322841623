import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { PropTypes } from "prop-types";

const RoyaltyForm = ({ data }) => {

    return (
        <Card>
            <TextField
                autoFocus
                required
                margin="dense"
                id="range"
                name="range"
                defaultValue={data.range}
                label="Range"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="level"
                name="level"
                defaultValue={data.level}
                label="Level"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="rate"
                name="rate"
                defaultValue={data.rate}
                label="Rate"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="rule"
                name="rule"
                defaultValue={data?.rule}
                label="Rule"
                type="text"
                fullWidth
                variant="standard"
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="designation"
                name="designation"
                defaultValue={data.designation}
                label="Designation"
                type="text"
                fullWidth
                variant="standard"
            />


            <FormControl fullWidth>
                <InputLabel id="status-label" sx={{ transform: 'translate(0, 1.5px) scale(0.75)', marginLeft: "10px" }}>
                    Status
                </InputLabel>
                <Select
                    labelId="status-label"
                    id="status"
                    name="status"
                    defaultValue={data.status ?? true}
                    sx={{
                        '& .MuiSelect-root': {
                            padding: '10px',
                        },
                    }}
                >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                </Select>
            </FormControl>
        </Card>
    )
}

export default RoyaltyForm;

RoyaltyForm.defaultProps = {
    data: {},
    type: "new"
}

RoyaltyForm.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string
}