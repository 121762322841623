class RewardsModel {
  constructor(id, range, rule, type, status, reward, createdAt, updatedAt) {
    this.id = id;
    this.range = range;
    this.rule = rule;
    this.type = type;
    this.status = status;
    this.reward = reward;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromArray(jsonData) {
    if (!jsonData || !Array.isArray(jsonData)) {
      console.error("jsonData is empty or not an array.");
      return [];
    }

    return jsonData.map(
      (JSON) =>
        new RewardsModel(
          JSON._id ?? null,
          JSON.range,
          JSON.rule,
          JSON.type,
          JSON.status ?? true,
          JSON.reward,
          JSON.createdAt ?? new Date(),
          JSON.updatedAt ?? new Date()
        )
    );
  }

  toDatabaseJson() {
    return {
      range: this.range,
      rule: this.rule,
      type: this.type,
      status: this.status,
      reward: this.reward,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}

export default RewardsModel;
