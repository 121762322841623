/* eslint-disable react/prop-types */
// Knocial Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { setDialog } from "context";
import SourceForm from "../form";
import { updateSource, deleteSource } from "Services/endpointes";
import { toast } from "react-toastify";
import ApiClient from "Services/ApiClient";

function Status({ status }) {
  if (status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="Active" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge
            variant="gradient"
            badgeContent="Inactive"
            color="Warning"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  }
}

const editSource = (id, getSources) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateSource, formData);
    toast.success(response.message);
    getSources();
  } catch (error) {
    console.error("Error adding source:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete source. Please try again later."
    );
  }
};

const deleteSources = (id, getAllSources) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteSource, id);
    toast.success(response?.message);
    getAllSources();
  } catch (error) {
    console.error("Error deleting source:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete source. Please try again later."
    );
  }
};

const SourcesView = {
  columns: [
    { name: "title", align: "left" },
    { name: "type", align: "center" },
    { name: "rule", align: "center" },
    { name: "range", align: "center" },
    { name: "status", align: "center" },
    { name: "update", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllSources) => {
    return data.map((e) => {
      const dateObject = new Date(e.createdAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const formattedDate = dateObject.toLocaleDateString("en-GB", options);

      return {
        title: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.title}
          </SoftTypography>
        ),
        type: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.type}
          </SoftTypography>
        ),

        rule: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.rule}
          </SoftTypography>
        ),
        range: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.range}
          </SoftTypography>
        ),
        status: <Status status={e.status} />,
        update: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {formattedDate}
          </SoftTypography>
        ),
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="4px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editSource(e.id, getAllSources),
                    message: `UPDATE - ${e.type.replace("-", " ").toUpperCase()}`,
                    action: "Update",
                    children: <SourceForm data={e} />,
                  },
                ]);
              }}
              cursor="pointer"
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: deleteSources(e.id, getAllSources),
                    action: "Delete",
                    message: `DELETE - ${e.type}`,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="danger">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default SourcesView;
