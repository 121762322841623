class UserModel {
  constructor(
    id,
    initial,
    fullName,
    password,
    email,
    phone,
    image,
    dob,
    status,
    type,
    attempt,
    isVerified,
    users,
    connections,
    storage,
    withdrawRequest,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.initial = initial;
    this.fullName = fullName;
    this.password = password;
    this.email = email;
    this.phone = phone;
    this.image = image;
    this.dob = dob;
    this.status = status;
    this.type = type;
    this.attempt = attempt;
    this.isVerified = isVerified;
    this.users = users;
    this.connections = connections;
    this.storage = storage;
    this.withdrawRequest = withdrawRequest;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new UserModel(
      jsonData.userId ?? "",
      jsonData.initial ?? "",
      jsonData.fullName ?? "User",
      jsonData.password ?? "",
      jsonData.email ?? "",
      jsonData.phone ?? "",
      jsonData.image ?? "",
      jsonData.dob ?? "dd-mm-yyyy",
      JSON.parse(jsonData?.status) ?? false,
      jsonData.type ?? "admin",
      jsonData?.attempt ?? 5,
      JSON.parse(jsonData?.isVerified) ?? false,
      jsonData.users,
      jsonData.connections,
      jsonData.storage,
      jsonData.withdrawRequest,
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }
}

export default UserModel;
