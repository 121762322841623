class UsersModel {
  constructor(
    id,
    userId,
    initial,
    fullName,
    email,
    phone,
    image,
    dob,
    status,
    type,
    attempt,
    isVerified,
    sponsorId,
    placementId,
    placementLevel,
    level,
    storage,
    members,
    limitRewardId,
    rewardId,
    designationId,
    totalEarn,
    totalWithdraw,
    wallet,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.userId = userId;
    this.initial = initial;
    this.fullName = fullName;
    this.email = email;
    this.phone = phone;
    this.image = image;
    this.dob = dob;
    this.status = status;
    this.type = type;
    this.attempt = attempt;
    this.isVerified = isVerified;
    this.sponsorId = sponsorId;
    this.placementId = placementId;
    this.placementLevel = placementLevel;
    this.level = level;
    this.storage = storage;
    this.members = members;
    this.limitRewardId = limitRewardId;
    this.rewardId = rewardId;
    this.designationId = designationId;
    this.totalEarn = totalEarn;
    this.totalWithdraw = totalWithdraw;
    this.wallet = wallet;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new UsersModel(
      json._id,
      json.userId,
      json.initial,
      json.fullName ?? "User",
      json.email,
      json.phone,
      { imageType: jsonData?.imageMimeType ?? "", imageBuffer: jsonData?.imageBuffer ?? "" },
      json.dob ?? "dd-mm-yyyy",
      json.status ?? false,
      json.type ?? "user",
      json.attempt ?? 5,
      json.isVerified ?? false,
      json.sponsorId ?? "",
      json.placementId ?? "",
      json.placementLevel ?? 0,
      json.level ?? 0,
      {
        own: json.storage?.own ?? 0,
        member: json.storage?.member ?? 0,
        usable: json.storage?.usable ?? 0,
      },
      json.members,
      json.limitRewardId ?? [],
      json.rewardId ?? [],
      json.designationId ?? "",
      json.totalEarn ?? 0,
      json.totalWithdraw ?? 0,
      json.wallet ?? 0,
      json.createdAt ?? new Date(),
      json.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    return jsonData.map((json) => {
      return new UsersModel(
        json._id,
        json.userId,
        json.initial,
        json.fullName ?? "User",
        json.email,
        json.phone,
        { imageType: jsonData?.imageMimeType ?? "", imageBuffer: jsonData?.imageBuffer ?? "" },
        json.dob ?? "dd-mm-yyyy",
        json.status ?? false,
        json.type ?? "user",
        json.attempt ?? 5,
        json.isVerified ?? false,
        json.sponsorId ?? "",
        json.placementId ?? "",
        json.placementLevel ?? 0,
        json.level ?? 0,
        {
          own: json.storage?.own ?? 0,
          member: json.storage?.member ?? 0,
          usable: json.storage?.usable ?? 0,
        },
        json.members,
        json.limitRewardId ?? [],
        json.rewardId ?? [],
        json.designationId ?? "",
        json.totalEarn ?? 0,
        json.totalWithdraw ?? 0,
        json.wallet ?? 0,
        json.createdAt ?? new Date(),
        json.updatedAt ?? new Date()
      );
    });
  }
}

export default UsersModel;
