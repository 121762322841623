import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

const LimitRewardForm = ({ data }) => {
  return (
    <Card>
      <TextField
        autoFocus
        required
        margin="dense"
        id="type"
        name="type"
        value={data.type}
        label="Type"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="reward"
        name="reward"
        value={data.reward}
        label="reward"
        type="text"
        fullWidth
        variant="standard"
      />

      <FormControl fullWidth>
        <InputLabel
          id="status-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          name="status"
          defaultValue={data.status}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
export default LimitRewardForm;

LimitRewardForm.defaultProps = {
  data: {},
  type: "new",
};

LimitRewardForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
};
